

import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import BodyWrapper from '../components/Layouts/BodyWrapper'
import Footer from '../components/MediatorComponents/Footer'
import NextPage from '../components/ResuableComponents/NextPage'
import Layout from "../components/layout"
import Seo from "../components/seo.js"
import { MobileContext } from '../components/Context/mobile'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
const Studio = ({ location, data }) => {

    const { mobile } = useContext(MobileContext)
    const nextNumber = data.allProducts.edges.length


    return (
        <Layout location={location} >
            <Seo title="Studio" />
            <BodyWrapper className=" c-15">
                <div className="flex flex-r flex-c-br " id="fixed-elements">
                    <div className="p-r-15 w-50p w-100p-br p-r-0-br">
                        {!mobile ? <div className="   p-t-15 w-100p p-b-15 5 h-100vh p-t-57-br " data-scroll-sticky data-scroll data-scroll-target="#fixed-elements" >
                            <GatsbyImage image={data.studio?.image.gatsbyImageData} className="h-100p p-b-15 w-100p" alt="" />
                        </div> : <div className="   p-t-15 w-100p p-b-15 5 h-100vh p-t-57-br "  >
                            <GatsbyImage image={data.studio?.image.gatsbyImageData} className="h-100p p-b-15 w-100p" alt="" />
                        </div>}
                    </div>
                    <div className="w-50p w-100p-br flex-c p-t-15 ">
                        {data.studio.sections.map((section, index) => {
                            return (
                                <div className="flex center-v center-h h-100vh re flex-c-br h-100vh-br-u al-i-s-br">

                                    <h3 className="m-b-90-br">{section.name}</h3>
                                    <div className="ab re-br b-30 r-0 m-w-265 m-w-270 m-b-75-br">
                                        <div className="m-b-30">
                                            <h4 className="indent"> .{index + 1} </h4>
                                            <p>
                                                {section.heading}</p>
                                        </div>

                                        <ReactMarkdown className="i-i-first indent">
                                            {section.paragraph}
                                        </ReactMarkdown>


                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
                <NextPage nextPageName={'Collection'} nextPageSlug={'/collections'} location={location} className="m-t-0-br" number={nextNumber} display={true} />
                <Footer location={location} />
            </BodyWrapper>
        </Layout>
    )
}

export default Studio

export const query = graphql`
    query myQuery {
        studio:datoCmsStudio {
            sections {
              name
              heading
              paragraph
            }
            image {
              gatsbyImageData
            }
          }
        nextPageQuery : allDatoCmsProductcat{
            edges {
            node {
                name
                slug
            }
        }
        
        }

        allProducts:allDatoCmsProduct {
            edges {
            node {
                id
                cat {
                name
                }
            }
            }
        }
    }
`